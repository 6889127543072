exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-editorial-js": () => import("./../../../src/pages/editorial.js" /* webpackChunkName: "component---src-pages-editorial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materials-js": () => import("./../../../src/pages/materials.js" /* webpackChunkName: "component---src-pages-materials-js" */),
  "component---src-pages-ux-js": () => import("./../../../src/pages/ux.js" /* webpackChunkName: "component---src-pages-ux-js" */),
  "component---src-templates-editorial-detail-js": () => import("./../../../src/templates/editorial-detail.js" /* webpackChunkName: "component---src-templates-editorial-detail-js" */),
  "component---src-templates-material-detail-js": () => import("./../../../src/templates/material-detail.js" /* webpackChunkName: "component---src-templates-material-detail-js" */),
  "component---src-templates-ux-detail-js": () => import("./../../../src/templates/ux-detail.js" /* webpackChunkName: "component---src-templates-ux-detail-js" */)
}

